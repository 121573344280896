// variables
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1200px
);

$enable-shadows: true;
$page-max-width: 1440px;

// https://coolors.co/ef476f-ffd166-06d6a0-118ab2-073b4c
$primary: #6ac29f;
$primary-light: lighten($primary, 31%);
$primary-lighter: lighten($primary, 36%);
$primary-dark: darken($primary, 16%);
$secondary: #cc047f;
$secondary-light: lighten($secondary, 62%);
$secondary-lighter: lighten($secondary, 65%);

$ideafactory-black: #111111;
$ideafactory-admin: #404040;
$ideafactory-grey: #333333;
$ideafactory-grey-light: #b4b4b4;
$ideafactory-grey-lighter: #ebebeb;
$ideafactory-grey-lightest: #f6f6f6;

$ideafactory-logo-color: $secondary;
$ideafactory-logo-text-color: $ideafactory-black;
$ideafactory-icon-color: $ideafactory-grey;

$ideafactory-sidebar-secondary-color: $secondary-light;
$ideafactory-sidebar-box-default-color: $ideafactory-grey-lightest;
$ideafactory-sidebar-box-postpone-color: $primary-lighter;
$ideafactory-sidebar-box-handover-color: $secondary-lighter;
$ideafactory-sidebar-box-collaboration-color: $secondary;
$ideafactory-sidebar-box-countdown-color: $secondary;

$ideafactory-card-development-idea-color: $primary-lighter;
$ideafactory-card-development-idea-footer-color: $primary-light;
$ideafactory-badge-development-color: $primary;

$ideafactory-community-ideas-color: $secondary;
$ideafactory-community-comments-color: #06d6a0;
$ideafactory-community-users-color: $ideafactory-grey-light;

$ideafactory-news-image-color: $primary;
$ideafactory-notifications-evaluation-border-color: $primary;

$theme-colors: () !default;
$theme-colors: map-merge(
                (
                        "ideafactory-admin": $ideafactory-admin,
                ),
                $theme-colors
);

$body-bg: #FFFFFF;
$body-color: $ideafactory-grey;
$link-color: $secondary;
$link-hover-color: darken($link-color, 5%);
$link-hover-decoration: none;
$box-shadow: 0 .125rem 1.25rem rgba(black, .15); //0 2px 20px 0 rgba(0, 0, 0, 0.15);
$box-shadow-light: 0 2px 4px 0 rgba(0, 0, 0, 0.02);

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-family-sans-serif: 'Lato', sans-serif;
$headings-color: $ideafactory-black;
$headings-font-family: 'Merriweather Sans', sans-serif;
$headings-font-weight: 700;

$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

$btn-border-radius: .25rem;
$btn-border-radius-lg: .3rem;
$btn-border-radius-sm: .2rem;

$popover-header-padding-x: 1rem;
$popover-border-color: transparent;
$popover-border-radius: 4px;
$popover-box-shadow: $box-shadow;

$navbar-padding-x: 0;
$navbar-padding-y: 0.975rem;
$navbar-nav-link-padding-x: 0;

$navbar-light-color: rgba($ideafactory-black, 1);
$navbar-light-hover-color: rgba($ideafactory-black, 1);
$navbar-light-active-color: rgba($ideafactory-black, 1);

$nav-tabs-link-active-color: $ideafactory-black;
$nav-tabs-link-active-bg: #FFF;

$card-cap-bg: #FFF;

$spacer: 1rem;
$spacers: (); // add more spacer classes
$spacers: map-merge(
                (
                        5: ($spacer * 2),
                        6: ($spacer * 3),
                        7: ($spacer * 4),
                        8: ($spacer * 5),
                        9: ($spacer * 6)
                ),
                $spacers
);

$card-border-radius: 1px;
$card-border-color: transparent;
$card-border-color-header: transparent;
$card-spacer-x: 1.125rem;

$modal-content-border-radius: $border-radius;
$modal-header-border-color: #FFF;
$modal-footer-border-color: #FFF;
$modal-content-border-color: #FFF;
$modal-content-box-shadow-xs: $box-shadow;
$modal-content-box-shadow-sm-up: $box-shadow;

$carousel-indicator-width: 9px;
$carousel-indicator-height: 9px;
$carousel-indicator-active-bg: #b5b5b5;

$pagination-color: $link-color;
$pagination-bg: #FFF;
$pagination-border-color: #FFF;

$pagination-active-color: #FFF;
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;

$badge-font-size: 1rem;
$badge-border-radius: $btn-border-radius;

$input-placeholder-color: $ideafactory-grey-light;

// imports
@import "bootstrap.scss";
@import "font-awesome.scss";
@import "fonts";
@import "loader.scss";
@import "select2.scss";
@import "widget.scss";
@import "circle";
@import "~datatables.net-bs4/css/dataTables.bootstrap4";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4";

// Generate font-size utility classes.
// Must be defined after bootstrap imports.
// .font-size-{value} for xs
// .font-size-{breakpoint}-{value} for sm, md, lg, and xl.
// eg .font-size-sm-14
$font-sizes: (
        10: 0.625rem,
        11: 0.688rem,
        12: 0.75rem,
        13: 0.813rem,
        14: 0.875rem,
        15: 0.9375rem,
        16: 1rem,
        18: 1.125rem,
        20: 1.250rem,
        22: 1.375rem,
        24: 1.5rem,
        30: 1.875rem,
        32: 2rem,
        38: 2.375rem
);
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $key, $value in $font-sizes {
      .font-size#{$infix}-#{$key} {
        font-size: $value;
      }
    }
  }
}

/**
 Generate utility classes for backgrounds, texts and badges (bg-color, text-color, badge-color).
 If we put these colors to the theme-colors map, the full stack of bootstrap helper classes is created.
 (buttons, tables, alerts, list-groups).
 */
$theme-utility-colors: () !default;
$theme-utility-colors: map-merge(
                (
                        "ideafactory-black": $ideafactory-black,
                        "ideafactory-grey": $ideafactory-grey,
                        "ideafactory-grey-light": $ideafactory-grey-light,
                        "ideafactory-grey-lighter": $ideafactory-grey-lighter,
                        "ideafactory-grey-lightest": $ideafactory-grey-lightest,
                ),
                $theme-utility-colors
);

@each $color, $value in $theme-utility-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

@each $color, $value in $theme-utility-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

@each $color, $value in $theme-utility-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-utility-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

// Generate border-left utility classes foreach theme color
// Must be defined after bootstrap imports.
// eg .bl .bl-secondary
.bl {
  border-left: 10px solid $primary;
}

@each $color, $value in $theme-colors {
  .bl-#{$color} {
    @extend .bl;
    border-color: $value !important;
  }
}


// Generate utility classes for each idea state
// Background - .bg-state--THE_STATE
// Border left - .bl-state--THE_STATE
// Border right - .bl-state--THE_STATE
// Icon - .icon-state----THE_STATE

$state-red: #DA2032;
$state-green: #62B339;
$state-blue: #007A9B;
$state-yellow: #FFDC00;
$state-grey: #CCCCCC;

$state-colors: (
        "red": $state-red,
        "green": $state-green,
        "blue": $state-blue,
        "yellow": $state-yellow,
        "submission": $state-yellow,
        "submitted": $state-yellow,
        "evaluation": $state-yellow,
        "evaluated": $state-yellow,
        "implementation": $state-yellow,
        "implemented": $state-yellow,
        "development": $state-yellow,
        "conception": $state-yellow,
        "canceled": $state-yellow,
        "conceptionCanceled": $state-yellow,
        "notnew": $state-yellow,
        "unknown": $state-grey
);

.icon-state {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: $ideafactory-grey-light;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

@each $state, $color in $state-colors {
  .bg-state--#{$state} {
    background-color: $color;
  }
  .bl-state--#{$state} {
    border-left: 5px solid $color;
  }
  .br-state--#{$state} {
    border-right: 5px solid $color;
  }
  .icon-state--#{$state} {
    @extend .icon-state;
    background-color: $color;
  }
}

body {
  margin: 0;
  padding: 0;
}

.container-fluid {
  max-width: $page-max-width;
}

.row--cols-equal-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row--cols-equal-height > [class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.white-space-pre {
  white-space: pre-line;
}

.actionbar {
  width: 100%;
  @extend .py-5;
  @extend .bg-secondary;
}

// sets some extra space at the bottom to ensure the actionbar is not hiding content.
.actionbar-wrapper::before {
  content: '';
  display: block;
  width: 100%;
  min-height: 120px;
  border: 1px solid transparent;
}


@include media-breakpoint-up(md) {
  .actionbar {
    position: fixed;
    bottom: 0;
    left: 0;
    max-height: 102px;
    z-index: 998;
  }
}

h1 > a, h1 > a:hover,
h2 > a, h2 > a:hover,
h3 > a, h3 > a:hover,
h4 > a, h4 > a:hover,
h5 > a, h5 > a:hover,
h6 > a, h6 > a:hover {
  color: $headings-color;
}

.text-label {
  color: #B5B5B5;
}

label {
  @extend .font-size-14;
  @extend .text-label;
  text-transform: uppercase;
}

.custom-control-label--inline {
  padding-top: 0.1rem;
}

.clickable {
  cursor: pointer;
}

.font-family-heading {
  font-family: $headings-font-family;
}

.font-family-body {
  font-family: $font-family-sans-serif;
}

.collapse-toggle {
  @extend .clickable;
}

#header {
  background-color: $primary;
}

// Content and sidebar columns
.col--content,
.col--content-small,
.col--sidebar {
  @extend .px-2;
  @extend .py-3;
}

.col--actionbar,
.col--actionbar-small {
  @extend .px-2;
}

@include media-breakpoint-up(md) {
  .col--content,
  .col--content-small,
  .col--sidebar,
  .col--actionbar,
  .col--actionbar-small {
    @extend .px-md-3;
  }
}

@include media-breakpoint-up(lg) {
  .col--content,
  .col--content-small {
    @extend .col-lg-8;
    @extend .py-lg-7;
    @extend .pr-lg-4;
  }

  .col--sidebar {
    @extend .col-lg-4;
    @extend .py-lg-7;
  }

  .col--actionbar,
  .col--actionbar-small {
    @extend .col-lg-8;
    @extend .pr-lg-4;
  }
}

@include media-breakpoint-up(xl) {
  .col--content,
  .col--actionbar {
    @extend .pl-xl-5;
    @extend .pr-xl-8;
  }

  .col--content-small {
    @extend .pl-xl-7;
    @extend .pr-xl-9;
  }

  .col--sidebar {
    @extend .px-xl-4;
  }

  .col--actionbar-small {
    @extend .pl-xl-7;
    @extend .pr-xl-9;
  }
}

.col--content-bg {
  background-image: url("../images/ideafactory-bg-magenta.svg");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 85%;
}

.sidebar-box-header {
  @extend .text-white;
  @extend .text-center;
  @extend .py-1;
}

.sidebar-box-header--admin {
  @extend .bg-ideafactory-admin;
}

.sidebar-box {
  @extend .px-5;
  @extend .py-5;
  margin-bottom: 64px; // cannot use @extend .mb-7; because some boxes need to overwrite this value
}

.sidebar-box--default {
  background-color: $ideafactory-sidebar-box-default-color !important;
}

.sidebar-box--postpone {
  background-color: $ideafactory-sidebar-box-postpone-color !important;
}

.sidebar-box--handover {
  background-color: $ideafactory-sidebar-box-handover-color !important;
}

.sidebar-box--collaboration {
  background-color: $ideafactory-sidebar-box-collaboration-color !important;
  @extend .text-white;
}

.sidebar-box--countdown {
  background-color: $ideafactory-sidebar-box-countdown-color !important;
  @extend .text-white;
}

.navbar-nav {
  margin-top: 2rem;
  font-family: $headings-font-family;
  font-weight: 700;
  @extend .font-size-18;
}

.navbar-nav .nav-link {
  text-transform: uppercase;
}

.navbar-light {
  .navbar-nav {
    .nav-item {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.navbar-brand {
  order: 1
}

.navbar-toggler {
  border: 0;
  order: 2;
}

.navbar-toggler:focus {
  outline: transparent none 0;
}

.navbar-collapse {
  order: 3;
}

@include media-breakpoint-down(lg) {
  .navbar-collapse {
    order: 5;
  }
}

.navbar-profile {
  order: 4;
}

@include media-breakpoint-up(xl) {
  .navbar-nav {
    margin-top: 0;
    @extend .align-items-xl-end;
    min-height: 98px;
  }

  .navbar-light {
    .navbar-nav {
      .nav-item {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .nav-link {
        position: relative;
      }

      .nav-link.active-path {
        color: $navbar-light-active-color;
      }

      .nav-link.active-path:before,
      .nav-link:hover:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1 * $navbar-padding-y;
        height: 8px;
        width: 100%;
        background-color: $ideafactory-black;
      }
    }
  }
}

.btn-secondary--nav-link {
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}

.px-grid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

.mx-grid-reset {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
}

.row--inner {
  margin-left: 0;
  margin-right: 0
}

.row--odd {
  background-color: $ideafactory-grey-lightest;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay {
  position: relative;
}

.overlay > * {
  z-index: 2
}

#notifications {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: $page-max-width;
  z-index: 2000;
}

#notifications-count {
  position: absolute;
  right: -10px;
  top: 3px;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  display: block;
  text-align: center;
  padding-top: 2px;
  z-index: 2;
  @extend .bg-secondary;
  @extend .text-white;
  @extend .font-size-12;
}

.notification {
  border-top: 1px solid $ideafactory-grey-light;
  padding: .7rem 0;
}

.notification--evaluation {
  @extend .bl-primary;
  @extend .pl-3;
  border-color: $ideafactory-notifications-evaluation-border-color !important;
}

.notification__close {
  position: relative;
  top: -3px;
  @extend .text-secondary;
}

.validationMessage, .text__validationMessage, .form__validationMessage {
  display: block;
  color: #721c24;
  @extend .font-size-14;
  padding-top: .1rem;
  padding-left: $input-padding-x;
}

.form-group-autocomplete {
  position: relative;
}

.autocomplete-reset {
  position: absolute;
  top: .4rem;
  right: .4rem;
}

.knockout-autocomplete.after-menu {
  width: 100%;
  z-index: 999;
}

.knockout-autocomplete.menu {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.btn-default {
  @extend .btn-light;
  color: $secondary;
  border: 1px solid $secondary;
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  color: $secondary;
}

.btn--login {
  position: relative;
  top: -1.7rem;
  @extend .px-4;
}

.section-label,
.col--label {
  font-family: $font-family-base;
  color: $ideafactory-grey-light;
  text-transform: uppercase;
}

.section-label {
  @extend .font-size-24;
}

.col--label {
  @extend .font-size-12;
  padding-top: .4rem;
  margin-bottom: .4rem;
}


// Ideas list

.card-ideas {
  border: 0px solid transparent;
  position: relative;
  background-color: $ideafactory-grey-lightest;
}

.card-ideas__icons {
  position: absolute;
  top: -4px;
  left: -4px;
}

.card-ideas__likes {
  padding: 3px 7px 4px 7px;
  @extend .bg-secondary;
  @extend .text-white;
  border-radius: $btn-border-radius;
}

.badge-ideafactory-grey-lightest {
  @extend .text-secondary;
}

.badge-development {
  background-color: $ideafactory-badge-development-color !important;
}

.badge-campaign {
  @extend .badge-ideafactory-admin;
  @extend .text-white;
}

.card-ideas__image {
  height: 200px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @extend .bg-secondary;
}

.card-ideas__title {
  @extend .font-size-20;
  @extend .mb-3;
  min-height: 48px;
}

.card-ideas__subtitle {
  @extend .font-size-13;
  @extend .mb-2;
}

.card-ideas__user-name {
  display: block;
  @extend .font-size-14;
  font-weight: bold;
  margin-bottom: .1rem;
}

.card-ideas__user-info {
  display: block;
  @extend .font-size-12;
}

.card-ideas__body {
  padding-bottom: 1.5rem;
}

.card-ideas__footer {
  background-color: $ideafactory-grey-lighter;
  padding-top: .275rem;
  padding-bottom: .4rem;
}

.card-ideas__state {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: .2rem;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.card-ideas--development-idea {
  background-color: $ideafactory-card-development-idea-color;
}

.card-ideas--development-idea .card-ideas__footer {
  background-color: $ideafactory-card-development-idea-footer-color;
}

.idea-user-name--anonym {
  @extend .text-ideafactory-grey-light;
}

.ideas-footer {
  //background-image: url("../images/bg-footer-magenta.svg");
  //background-size: auto;
  background-image: url("../images/ideafactory-bg-white.svg");
  background-size: 35%;
  background-position: center center;
  @extend .bg-secondary;
}

// Idea details

.idea__heading-2 {
  @extend .font-size-20;
  @extend .mt-4;
  @extend .mb-3;
}

.idea__image {
  height: 270px;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-position: center center;
}

.comment-images-carousel {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  max-height: 3000px;
}

.comment-images-carousel .idea__image {
  height: 200px;
}

.col--sidebar .comment-images-carousel .idea__image {
  height: 250px;
}

@include media-breakpoint-up(sm) {
  .idea__image {
    height: 370px;
  }
}

@include media-breakpoint-up(md) {
  .idea__image {
    height: 470px;
  }

  .comment-images-carousel .idea__image {
    height: 300px;
  }
}

.idea__attachment-actions {
  position: absolute;
  left: -8px;
  top: -10px;
}

.idea__image-thumbnail {
  display: block;
  height: 170px;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-position: center center;
}

@include media-breakpoint-up(sm) {
  .idea__image-thumbnail {
    height: 200px;
  }
}

@include media-breakpoint-up(md) {
  .idea__image-thumbnail {
    height: 220px;
  }
}

.idea__profile-name {
  @extend .font-size-20;
  @extend .font-weight-bold;

}

.idea__evaluation {
  margin-top: 2rem;
  border-left: 10px solid $primary;
  background-color: $ideafactory-grey-lighter;
}

.idea__implementation-description {
  margin-top: 2rem;
  border-left: 10px solid $secondary;
  background-color: $ideafactory-grey-lighter;
}

.text-length-indicator {
  padding-left: 8px;
  @extend .font-size-14;
  position: relative;
  top: $form-group-margin-bottom * -.8;
  margin-bottom: $form-group-margin-bottom * 1.2;

}

.text-length-indicator__label, .text-length-indicator__color {
  vertical-align: middle;
}

.text-length-indicator__color {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.5rem;
  width: 15px;
  height: 15px;
}

.text-length-indicator__color--small {
  background-color: $danger;
}

.text-length-indicator__color--medium {
  background-color: $warning;
}

.text-length-indicator__color--large {
  background-color: $success;
}

// Evaluation, criterias and rating

.evaluation__idea-image {
  min-height: 210px;
  background-image: url("../images/dummy.png");
  background-size: cover;
}

.evaluation_idea-details {
  @extend .bg-ideafactory-grey-lightest;
  @extend .px-6;
  @extend .py-4;
}

.criteria {
  @extend .pb-2;
  @extend .mb-3;
  border-bottom: 1px solid $ideafactory-grey-lighter;
}

.criteria__type-title {
  @extend .font-size-12;
  line-height: 1.3em;
  color: $secondary;
}

.criteria__type-text {
  @extend .font-size-12;
  line-height: 1.3em;
  color: #B5B5B5;
}

.criteria__add-comment {
  @extend .font-size-12;
  font-style: italic;
  color: $secondary;
}

.rating {

}

.rating__star {
  color: #C7C7C7;
}

.rating__star--active {
  color: $primary;
}


// User

.user-image {
  background-color: $primary;
  display: inline-block;
  width: 48px;
  height: 48px;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-color: $secondary;
}

.user-image--circle {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-right: 0.5rem;
}

.user-image--circle-medium {
  @extend .user-image--circle;
  width: 70px;
  height: 70px;
}

.user-image--circle-big {
  @extend .user-image--circle;
  width: 120px;
  height: 120px;
}

.user-image--square-100 {
  width: 100px;
  height: 100px;
}

.user-image--header {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  background-color: $primary-dark;
  border-radius: 100%;
  width: 52px;
  height: 52px;
  position: relative;
  top: 4px;
}

.user-image--profile {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 220px;
  height: 220px;
  margin: 1rem 1.5rem;
}

.fa--remove-user {
  position: absolute;
  top: 50%;
  right: 10%;
  margin-top: -.6rem;
  z-index: 10;
}


// User profile

.card--profile {
  border: 1px solid #E5E5E5;
  background-color: #F6F6F6;
}

// Awards

.awards {
  border: 1px solid #E5E5E5;
  background-color: #F6F6F6;
  @extend .pt-3;
  @extend .pb-4;
}

.col--award {
  @extend .h-100;
  @extend .px-2;
}

.award-label {
  text-align: center;
  font-weight: bold;
  @extend .font-size-14;
  line-height: 1.1;
  @extend .mt-3;
  @extend .mb-1;
}

@include media-breakpoint-up(sm) {
  .award-label {
    min-height: 51px;
    white-space: pre-wrap;
    max-width: 110px;
    padding: 0 10px 0 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

.award-score {
  @extend .font-size-12;
  text-align: center;
}

.award-image {
  background-image: url("../images/awards/Award1_bronze.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  max-width: 80px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
}

.award-image-evaluator--gold {
  background-image: url("../images/awards/Award1_gold.png");
}

.award-image-evaluator--silver {
  background-image: url("../images/awards/Award1_silber.png");
}

.award-image-evaluator--bronze {
  background-image: url("../images/awards/Award1_bronze.png");
}

.award-image-perfectIdeas--gold {
  background-image: url("../images/awards/Award2_gold.png");
}

.award-image-perfectIdeas--silver {
  background-image: url("../images/awards/Award2_silber.png");
}

.award-image-perfectIdeas--bronze {
  background-image: url("../images/awards/Award2_bronze.png");
}

.award-image-ideasSubmitted--gold {
  background-image: url("../images/awards/Award3_gold.png");
}

.award-image-ideasSubmitted--silver {
  background-image: url("../images/awards/Award3_silber.png");
}

.award-image-ideasSubmitted--bronze {
  background-image: url("../images/awards/Award3_bronze.png");
}

.award-image-ideasImplemented--gold {
  background-image: url("../images/awards/Award4_gold.png");
}

.award-image-ideasImplemented--silver {
  background-image: url("../images/awards/Award4_silber.png");
}

.award-image-ideasImplemented--bronze {
  background-image: url("../images/awards/Award4_bronze.png");
}

.award-image-comments--gold {
  background-image: url("../images/awards/Award5_gold.png");
}

.award-image-comments--silver {
  background-image: url("../images/awards/Award5_silber.png");
}

.award-image-comments--bronze {
  background-image: url("../images/awards/Award5_bronze.png");
}

.award-image-codeveloper--gold {
  background-image: url("../images/awards/Award6_gold.png");
}

.award-image-codeveloper--silver {
  background-image: url("../images/awards/Award6_silber.png");
}

.award-image-codeveloper--bronze {
  background-image: url("../images/awards/Award6_bronze.png");
}


// News

.col--card-news {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  margin-bottom: 1rem;
}

.col--card-news-image {
  background-color: $ideafactory-news-image-color !important;
}

.col--card-news-text {
  @extend .bg-ideafactory-grey-lightest;
}

@include media-breakpoint-up(sm) {
  .card-news {
    min-height: 300px;
  }
}

.card-news,
.card-news__header,
.card-news__footer {
  background-color: transparent;
}

.card-news__title {
  min-height: 48px;
}

.card-news__date {
  @extend .mb-2;
  @extend .font-size-12;
  @extend .text-ideafactory-grey-light;
}

.card-news__image {
  height: 100px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.row--pinned-news {
  @extend .bg-ideafactory-grey-lightest;
}

.pinned-news,
.pinned-news__header,
.pinned-news__footer {
  background-color: transparent;
}

.pinned-news__title {
  min-height: 48px;
}

.pinned-news__date {
  @extend .mb-2;
  @extend .font-size-12;
  @extend .text-ideafactory-grey-light;
}

.pinned-news__image {
  height: 100%;
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sidebar-news {
  border-top: transparent;
  border-right: transparent;
  border-bottom: transparent;
  @extend .bl-primary;
  @extend .bg-ideafactory-grey-lightest;
}

.sidebar-news,
.sidebar-news__header,
.sidebar-news__footer {
  background-color: transparent;
}

.sidebar-news__date {
  @extend .mb-2;
  @extend .font-size-12;
  @extend .text-ideafactory-grey-light;
}

.sidebar-countdown {
  @extend .bg-ideafactory-grey-lightest;
}

.sidebar-countdown-text {
  @extend .bg-secondary;
  @extend .text-white;
}

// News detail page

.news-detail__teaser_image {
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// Campaigns

.col--card-campaign {
  margin-bottom: 3rem;
}

.card-campaign {
  border: 0px solid transparent;
  position: relative;
  background-color: $ideafactory-grey-lightest;
}

.card-campaign__icons {
  position: absolute;
  top: -4px;
  left: -4px;
}

.card-campaign__body {
  padding-bottom: 1.5rem;
}

.card-campaign__footer {
  background-color: $ideafactory-grey-lightest;
  padding-top: .5rem;
  padding-bottom: 1rem;
}


.card-campaign__title {
  @extend .font-size-20;
  @extend .mb-3;
  min-height: 48px;
}

.card-campaign__subtitle {
  color: $ideafactory-grey;
  @extend .font-size-13;
  @extend .mb-3;
  @extend .mt-1;
}

.card-campaign__image {
  height: 180px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.row--active-campaign {
  @extend .bg-ideafactory-grey-lightest;
}

.active-campaign__background {
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid transparent;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card--active-campaign {
  @extend .text-white;
}

.card--active-campaign,
.active-campaign,
.active-campaign__header,
.active-campaign__footer {
  background-color: transparent;
}

.active-campaign__title {
  min-height: 56px;
}

.active-campaign__image {
  height: 100%;
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sidebar-campaign {
  border-top: transparent;
  border-right: transparent;
  border-bottom: transparent;
}

.sidebar-campaign,
.sidebar-campaign__header,
.sidebar-campaign__footer {
  background-color: transparent;
}

// News detail page

.campaign-detail__teaser_image {
  min-height: 480px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// Comments

.comments,
.shadow-box {
  border: 1px solid #f6f6f6;
  border-radius: 10px;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.16);
  padding: 1rem .8rem;
}

.comment {
  border-top: 1px solid $ideafactory-grey-lighter;
  padding-top: 1.2rem;
}

.comments .comment:first-child {
  border-top: 0px solid $ideafactory-grey-lighter;
  padding-top: 0;
}

.comment-level-0 {
  margin-left: 0;
}

.comment-level-1 {
  margin-left: 4.4375rem;
}

.comment-level-2 {
  margin-left: 8.875rem;
}

.comment-level-3 {
  margin-left: 13.3125rem;
}

.comment-level-4 {
  margin-left: 17.75rem;
}


// Carousel

.carousel {
  margin-bottom: 5rem;
}

.carousel-inner--grey {
  @extend .bg-ideafactory-grey;
}

.carousel-indicators {
  bottom: -3.5rem;
}

.carousel-indicators > li {
  border-radius: 50%;
}

.carousel-item--campaign {
  height: auto;
}

@include media-breakpoint-up(sm) {
  .carousel-item--campaign {
    height: 476px;
  }
}

// Tabs

.nav-tabs .nav-link {
  background-color: $ideafactory-grey-lightest;
}


// Pagination

.page-link,
.page-item.disabled .page-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  background-color: $ideafactory-grey-lightest;
  border-color: $ideafactory-grey-lightest;
  margin-left: .2rem;
  margin-right: .2rem;
  border-top-left-radius: 5px; // have to be defined foreach corner to override bootstrap styles
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}


// Drag & drop

.drag-handle {
  cursor: move
}

@include media-breakpoint-down(sm) {
  .page-item.ks-boundary-first,
  .page-item.ks-boundary-last {
    display: none;
  }
}


// Admin Menu

.admin-content {
  min-height: 800px;
}

.admin-menu {
  @extend .bg-ideafactory-admin;
}

.admin-menu-header {
  letter-spacing: 2px;
  background-color: $ideafactory-grey;
  @extend .text-white;
  @extend .font-size-15;
  @extend .text-uppercase;
  @extend .px-3;
  @extend .py-4;
}

.admin-menu-item {
  display: block;
  margin-top: 1px;
  @extend .text-white;
  @extend .font-size-15;
  @extend .px-3;
  @extend .py-3;
}

.admin-menu-item:hover,
.admin-menu-item.active-path {
  background-color: $ideafactory-grey;
}

.admin-menu-item > .fa {
  @extend .pr-2;
}

.admin-menu-item-sub {
  display: block;
  margin-top: 1px;
  @extend .text-white;
  @extend .font-size-12;
  @extend .pr-3;
  @extend .pl-6;
  @extend .py-1;
}

.admin-menu-item-sub:hover,
.admin-menu-item-sub.active-path {
  background-color: $ideafactory-grey;
}

.admin-menu-item-sub > .fa {
  @extend .pr-2;
  @extend .text-ideafactory-grey-light;
}

.admin-menu-item-sub:hover .fa {
  @extend .text-white;
}

.admin-menu-item-sub.active-path .fa {
  @extend .text-primary;
}

.not-clickable {
  pointer-events: none;
  cursor: default;
}


// Admin dashboard

.row--dashboard-list-item {
  border-bottom: 1px solid #DBDBDB;
}

.card--admin-dashboard {
  border: 1px solid #f6f6f6;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.16);
  @extend .mb-5;
}

.card--admin-dashboard .card-header {
  background-color: $secondary;
  color: white;
}

.card--admin-dashboard .card-body {
  height: 390px;
  max-height: 390px;
  overflow-y: auto;
}

.card--admin-dashboard .card-footer {
  min-height: 56px;
  @extend .bg-ideafactory-grey-lightest;
}


// Admin categories

.card-category {
  border: 0px solid transparent;
  position: relative;
  background-color: $ideafactory-grey-lightest;
}

.card-category__image {
  height: 200px;
  display: block;
  background-image: url("../images/dummy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.card-category .card-footer {
  background-color: $ideafactory-grey-lighter;
}

.active-campaign__background::after {
  z-index: 2;
}


// Rankings

.card-rankings {

}

.card-rankings__header,
.card-rankings--primary .card-rankings__header {
  background-color: $primary;
  color: $white;
  text-align: center;
  font-weight: bold;
  @extend .font-size-18
}

.card-rankings--primary .my-list-entry {
  background-color: $primary-lighter;
}

.card-rankings--secondary .card-rankings__header {
  background-color: $secondary;
  color: $white;
}

.card-rankings--secondary .my-list-entry {
  background-color: $secondary-light;
}

.card-rankings__body {
  padding: 0;
}

.card-rankings__ranking {
  min-width: 3rem;
}

.card-rankings__user-image {
  @extend .user-image;
  @extend .user-image--circle;
  margin-right: 0;
  overflow: hidden;
}

.card-rankings__dots {
  letter-spacing: 2px;
  padding-bottom: .8rem;
  font-size: 3rem;
  line-height: 2rem;
  color: $ideafactory-grey-light;
  position: relative;
  top: -.3rem
}

.card-rankings__value {
  min-width: 3rem;
  text-align: right;
}

.ranking-row {
  padding-top: .5rem;
  padding-bottom: .2rem;
}

.ranking-row--odd {
  background-color: #F6F6F6;
}

.ranking-row--even {
  background-color: #EAEAEA;
}

// Statistics count

.count {
  @extend .text-white;
  @extend .text-center;
  @extend .py-5;
}

.count__value {
  font-family: $headings-font-family;
  font-size: 3.75rem; // 60px
}

#carousel-tutorial-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
  width: 100%;
  height: 100%;
  z-index: 10000;
}

#carousel-tutorial {
  max-width: 960px;
  max-height: 100%;
  margin-bottom: 0;
}

#carousel-tutorial img {
  max-height: 100vh;
}

#tutorial-dissmiss {
  position: absolute;
  right: .3rem;
  top: .3rem;
  z-index: 10001;
}

#tutorial-dissmiss label {
  position: relative;
  top: 1px;
}

// IE 11 bug fixes
// see https://stackoverflow.com/questions/20541306/how-to-write-a-css-hack-for-ie-11
@media all and (-ms-high-contrast: none) {
  // IE 11 vertical align bugfix - set height smaller than min height
  // see https://github.com/philipwalton/flexbugs/issues/231
  .navbar-nav--ideafactory {
    height: 90px;
  }
}

/*
 Bug fix for data tables button drop down menu
 */
.dt-button-collection .dropdown-menu {
  display: block;
}

.menu-separator {
  @extend .my-1;
  @extend .py-1;
  @extend .pl-6;
  @extend .pr-6;
  height: 1px;
}

.menu-separator:before {
  content: '';
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  width: 100%;
  line-height: 1px;
  height: 1px;
}

@media print {
  // force print background images
  .carousel-item.idea__image.active,
  .user-image,
  .evaluation__idea-image,
  .active-campaign__image,
  .card-campaign__image,
  .card-news__image,
  .pinned-news__image,
  .idea__image-thumbnail,
  .idea__image,
  .card-ideas__image,
  .news-detail__teaser_image,
  .img-fluid {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /* Firefox */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }
}

.ideafactory-logo {
  fill: $ideafactory-logo-color;
}

.ideafactory-logo-text {
  fill: $ideafactory-logo-text-color;
}

.assessments-total,
.community-ideas {
  @extend .bg-secondary;
}

.assessments-average,
.community-comments {
  background-color: $ideafactory-community-comments-color !important;
}

.community-users {
  background-color: $ideafactory-community-users-color !important;
}

.col--sidebar-create {
  background-color: $ideafactory-sidebar-secondary-color !important;
}

.evaluation-idea {
  @extend .bg-ideafactory-grey-lightest;
  position: relative;
  top: -.9rem;
  z-index: 10;
}

.evaluation-idea-icons {
  position: relative;
  z-index: 101;

}

.widget-countdown {
  @extend .bg-ideafactory-grey-lightest;
}

.print-page-break-inside-avoid {
  page-break-inside: avoid;
}

.print-page-break-before {
  page-break-before: always;
}

.print-page-break-after {
  page-break-after: always;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: transparent;
  border: none;
  width: 50px;
  z-index: 10;
  opacity: 0.8;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
}

.carousel-control-prev:before, .carousel-control-next:before {
  content: '';
  background-color: rgba(0,0,0,.2);
  width:40px;
  height:40px;
  border-radius: 100%;
  position: absolute;
  z-index: 100;
}

.carousel-control-prev:before {
  left: 6px;
}
.carousel-control-next:before {
  right: 6px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  z-index: 101;
}

@media print {
  .d-print-float-none {
    float: none !important;
  }

  .d-print-mt-2 {
    margin-top: 2rem;
  }

  body {
    font-size: 1.75rem;
  }

  h1, h2 {
    font-size: 2.5rem;
  }

  .idea__heading-2, .section-label {
    font-size: 2rem;;
  }

  .font-size-12 {
    font-size: 1.25rem;;
  }

  .font-size-14 {
    font-size: 1.5rem;;
  }

  .font-size-16 {
    font-size: 1.75rem;;
  }
}

.collapse--show {
  display: inline-block;
}

.collapse--hide {
  display: none;
}

.btn[aria-expanded="true"]  {
  .collapse--show {
    display: none;
  }

  .collapse--hide {
    display: inline-block;
  }
}

.admin-contact.ui-sortable-handle {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

.admin-contact.ui-sortable-handle a {
  cursor: pointer;
}
